import { useEffect } from "react";
import './App.css';

function App() {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://www.buzzsprout.com/2200798.js?container_id=buzzsprout-large-player&player=large";
    script.async = true;
    document.body.appendChild(script);
  return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <div className="App">
      <h2>Buzzsprout</h2>
      <div id='buzzsprout-large-player'></div>
      <h2>Transistor</h2>
      <iframe
        src='https://share.transistor.fm/e/build-your-saas/latest'
        width='100%'
        height='180'
        frameborder='0'
        scrolling='no'
        seamless='true'
        style={{ width: "100%;", height: "180px;" }} ></iframe>
      <h2>Acast</h2>
      <iframe src="https://embed.acast.com/vicegamingsnewpodcast/episode221-cleareverything-weneedtotalkanthem" style={{ border: 0}} allowfullscreen scrolling="no" allow="encrypted-media;"></iframe>
      <h2>Simplecast</h2>
      <iframe height="200px" width="100%" frameborder="no" scrolling="no" seamless src="https://player.simplecast.com/29acdbb6-987d-467d-9395-f4a6d86ae8a5?dark=false"></iframe>
      <h2>PodBean</h2>
      <iframe title="Water" allowtransparency="true" height="150" width="100%" style={{ border: "none", minWidth: "min(100%, 430px);"}} scrolling="no" data-name="pb-iframe-player" src="https://www.podbean.com/player-v2/?i=43fyu-1428ce1-pb&from=pb6admin&share=1&download=1&rtl=0&fonts=Arial&skin=1&font-color=auto&logo_link=episode_page&btn-skin=ff6d00"></iframe>
    </div>
  );
}

export default App;
